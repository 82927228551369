import React from 'react';
import PropTypes from 'prop-types';

import { BodyText, Select } from '@reapit/elements';

import { InputContainer } from '../__styles__';

import { InputField } from 'components/ui/inputs';

const ApproximateCheckCount = {
	zeroToFifty: '0-50',
	fiftyToOneFifty: '50-150',
	oneFiftyToThreeHundred: '150-300',
	threeHundredPlus: '300+',
};

const RegisterDataCapture = ({
	data,
	handleChange,
	errors,
	loading,
	onValidate,
	tenant_registered,
	validator,
}) => {
	return (
		<>
			<InputContainer>
				<BodyText hasBoldText>Email</BodyText>
				<InputField
					error={errors.email}
					field="email"
					onChange={handleChange}
					id="email"
					loading={loading}
					onValidate={onValidate}
					type="text"
					validator={validator}
					value={data.email}
					disabled
				/>
			</InputContainer>
			<InputContainer>
				<BodyText hasBoldText>First name</BodyText>
				<InputField
					error={errors.firstname}
					field="firstname"
					onChange={handleChange}
					id="firstname"
					loading={loading}
					onValidate={onValidate}
					placeholder="e.g. Jane"
					type="text"
					validator={validator}
					value={data.firstname}
				/>
			</InputContainer>
			<InputContainer>
				<BodyText hasBoldText>Last name</BodyText>
				<InputField
					error={errors.lastname}
					field="lastname"
					onChange={handleChange}
					id="lastname"
					loading={loading}
					onValidate={onValidate}
					placeholder="e.g. Smith"
					type="text"
					validator={validator}
					value={data.lastname}
				/>
			</InputContainer>
			{!tenant_registered && (
				<InputContainer>
					<BodyText hasBoldText>Company name</BodyText>
					<InputField
						error={errors.companyName}
						field="companyName"
						onChange={handleChange}
						id="companyName"
						loading={loading}
						onValidate={onValidate}
						type="text"
						validator={validator}
						value={data.companyName}
						disabled
					/>
				</InputContainer>
			)}
			<InputContainer>
				<BodyText hasBoldText>Job title</BodyText>
				<InputField
					error={errors.jobTitle}
					field="jobTitle"
					onChange={handleChange}
					id="jobTitle"
					loading={loading}
					onValidate={onValidate}
					placeholder="e.g. Sales negotiator"
					type="text"
					validator={validator}
					value={data.jobTitle}
				/>
			</InputContainer>
			{!tenant_registered && (
				<InputContainer>
					<BodyText hasBoldText>
						Approximately how many ID and/or AML checks does your company
						complete per month?
					</BodyText>
					<Select
						id="approximateCheckCount"
						onChange={(event) =>
							handleChange('approximateCheckCount', event.target.value)
						}
						disabled={loading}
						style={{ width: '300px' }}
					>
						<option value={ApproximateCheckCount.zeroToFifty}>0 - 50</option>
						<option value={ApproximateCheckCount.fiftyToOneFifty}>
							50 - 150
						</option>
						<option value={ApproximateCheckCount.oneFiftyToThreeHundred}>
							150 - 300
						</option>
						<option value={ApproximateCheckCount.threeHundredPlus}>300+</option>
					</Select>
				</InputContainer>
			)}
		</>
	);
};

RegisterDataCapture.propTypes = {
	data: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	handleChange: PropTypes.func,
	loading: PropTypes.bool,
	onValidate: PropTypes.func,
	tenant_registered: PropTypes.bool.isRequired,
	validator: PropTypes.func,
};

export default RegisterDataCapture;
