import * as yup from 'yup';

import isEmail from 'validator/lib/isEmail';

const schema = () => {
	const shape = {
		firstname: yup.string().required('First name is required'),
		lastname: yup.string(),
		email: yup
			.string()
			.test(
				'Should be a valid email address',
				'Should be a valid email address',
				(value) => {
					return !value || isEmail(value);
				}
			),
		companyName: yup.string().required('Company name is required'),
		jobTitle: yup.string().required('Job title is required'),
		approximateCheckCount: yup
			.string()
			.required('Approximate check count is required'),
	};

	return yup.object().shape(shape);
};

export default schema;
